import request from '@/utils/request'
//查询企业信息列表
export function getIndustryLabel(query) {
  return request({
    url: '/serverCompany/getIndustryLabel',
    method: 'get',
    params: query
  })
}
// 修改企业用户账号类型-改为主账号
export function updateAccountAccess(query) {
  return request({
    url: '/serverCompany/updateAccountAccess',
    method: 'get',
    params: query
  })
}
// 修改企业用户账号类型-改为子账号
export function updateByPassAccountAccess(query) {
  return request({
    url: '/serverCompany/updateByPassAccountAccess',
    method: 'get',
    params: query
  })
}
// 智参客户管理详情
export function companyDetail(query) {
  return request({
    url: '/serverCompany/companyDetail',
    method: 'get',
    params: query
  })
}
// 根据上级标签的父节点查询出下级标签
export function getChild(query) {
  return request({
    url: '/label/getChild',
    method: 'get',
    params: query
  })
}
//新增企业形象
export function addcompany(data) {
  return request({
    url: '/serverCompany/insert',
    method: 'post',
    data: data
  })
}
//修改
export function editcompany(data) {
  return request({
    url: '/serverCompany/update',
    method: 'post',
    data: data
  })
}
// 智参客户管理详情
export function getCompanyDetail(query) {
  return request({
    url: '/serverCompany/companyDetail',
    method: 'get',
    params: query
  })
}
//智参客户企业管理列表一览
export function companylist(data) {
  return request({
    url: '/serverCompany/getCompanyList',
    method: 'post',
    data
  })
}
//修改企业的账号周期
export function updateCompanyAccountCycle(data) {
  return request({
    url: '/serverCompany/updateCompanyAccountCycle',
    method: 'post',
    data
  })
}
//企业关联用户
export function getCorrelateUser(data) {
  return request({
    url: '/serverCompany/accountPage',
    method: 'post',
    data
  })
}
//查询企业信息列表
export function updateCompanyMemberl(data) {
  return request({
    url: '/serverCompany/updateCompanyMember',
    method: 'post',
    data
  })
}

//获取企业信息详情
export function getcompany(id) {
  return request({
    url: '/company/' + id,
    method: 'get'
  })
}
//删除企业
export function delcompany(idS) {
  return request({
    url: '/company/' + idS,
    method: 'delete'
  })
}

//新增智参企业标签
export function savelabel(data) {
  return request({
    url: '/serverCompany/insertCompanyLabel',
    method: 'post',
    data: data
  })
}
//编辑智参企业标签
export function updateInformationLabel(data) {
  return request({
    url: '/serverCompany/updateCompanyLabel',
    method: 'post',
    data: data
  })
}

//获取智参企业标签信息详情
export function getBylabelId(query) {
  return request({
    url: '/serverCompany/getById',
    method: 'get',
    params: query
  })
}
//导入企业
export function importCompany(data) {
  return request({
    url: '/serverCompany/importCompany',
    method: 'post',
    data
  })
}
//用户认证通过企业会员短信
export function userCertificate(query) {
  return request({
    url: '/auth/v1/userCertificate',
    method: 'get',
    params: query
  })
}
//根据字段模糊查询所有的合作伙伴数据
export function getPartners(query) {
  return request({
    url: '/partner/getPartners',
    method: 'get',
    params: query
  })
}
//设置合作伙伴
export function updateCompanyMember(data) {
  return request({
    url: '/serverCompany/updateCompanyMember',
    method: 'post',
    data
  })
}
//设置智参中心
export function updateCompanyZc(data) {
  return request({
    url: '/serverCompany/updateCompanyZc',
    method: 'post',
    data
  })
}
//智选客户企业管理列表一览
export function getIntelligentCompanyList(data) {
  return request({
    url: '/serverCompany/getIntelligentCompanyList',
    method: 'post',
    data
  })
}
//批量导出智选企业
export function importCompanyt(data) {
  return request({
    url: '/serverCompany/importIntelligentCompany',
    method: 'post',
    data
  })
}

//新增账号
export function addinsetUser(data) {
  return request({
    url: '/serverCompany/insetUser',
    method: 'post',
    data
  })
}
//报备列表一览
export function getReportPage(data) {
  return request({
    url: '/serverCompany/getReportPage',
    method: 'post',
    data
  })
}
//报备通过
export function applyPass(query) {
  return request({
    url: '/serverCompany/reportPass',
    method: 'get',
    params: query
  })
}
//报备不通过
export function applyNoPass(query) {
  return request({
    url: '/serverCompany/reportRefuse',
    method: 'get',
    params: query
  })
}
//查询报备中心的报备机构
export function getReportOrganization() {
  return request({
    url: '/serverCompany/getReportOrganization',
    method: 'get'
  })
}
//查看报备名单
export function getCompanyBindReport(query) {
  return request({
    url: '/serverCompany/getCompanyBindReport',
    method: 'get',
    params: query
  })
}
//批量报备通过
export function reportPassList(data) {
  return request({
    url: '/serverCompany/reportPassList',
    method: 'post',
    data
  })
}

//保存已选标签--企业
export function insertMatchCompanyLabel(data) {
  return request({
    url: '/label/insertMatchCompanyLabel',
    method: 'post',
    data
  })
}
//修改历史匹配标签数据--企业
export function updateMatchedInformationLabel(data) {
  return request({
    url: '/label/updateMatchedInformationLabel',
    method: 'post',
    data
  })
}
//内容管理数据一览
export function companyContentPage(data) {
  return request({
    url: '/serverCompany/companyContentPage',
    method: 'post',
    data
  })
}
//内容管理-实体数量
export function getCompanyContentNum(data) {
  return request({
    url: '/serverCompany/getCompanyContentNum',
    method: 'get',
    params: data
  })
}
//企业修改周报开启状态
export function updateWeeklyNewspaperStatus(data) {
  return request({
    url: '/serverCompany/updateWeeklyNewspaperStatus',
    method: 'post',
    data
  })
}
//智参周报列表
export function companyTabloidPage(data) {
  return request({
    url: '/serverCompany/companyTabloidPage',
    method: 'post',
    data
  })
}
//周报-实体数量
export function getTabloidContentNum(data) {
  return request({
    url: '/serverCompany/getTabloidContentNum',
    method: 'get',
    params: data
  })
}
//智选客户中心账号分页查询
export function listPage(data) {
  return request({
    url: '/clueCompanyAccount/listPage',
    method: 'get',
    params: data
  })
}

//智选用户账号新增
export function saveUserAccount(data) {
  return request({
    url: '/clueCompanyAccount/saveUserAccount',
    method: 'post',
    data
  })
}
//用户账号冻结/解冻
export function isFreeze(data) {
  return request({
    url: '/clueCompanyAccount/isFreeze',
    method: 'get',
    params: data
  })
}
//根据企业全称查询数据系统数据
export function getSystemDataParam(data) {
  return request({
    url: '/serverCompany/getSystemDataParam',
    method: 'get',
    params: data
  })
}

// 导出补录名单 /serverCompany/exportCompanyReportExcel
export function exportCompanyReportExcel(data) {
  return request({
    url: '/serverCompany/exportCompanyReportExcel',
    method: 'post',
    responseType: "blob",
    data
  })
}